<template>
    <div class="main_container">
        <div class="main_page">
            <div class="page_title">{{ $route.meta.title }}</div>
            <div class="page_containt">
                <div class="page_search">
                    <el-form ref="searchForm" :model="searchForm">
                        <el-row type="flex">
                            <el-col class="search_col">
                                <el-form-item size="small" prop="sort_id">
                                    <el-select v-model="searchForm.sort_id" size="small" popper-class="select"
                                        placeholder="全部分类" :loading="sortLoading">
                                        <el-option v-for="item in sortList" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col class="search_col">
                                <el-form-item size="small" prop="key">
                                    <el-input size="small" type="text" v-model="searchForm.key" style="width: 150px"
                                        placeholder="名称" />
                                </el-form-item>
                            </el-col>
                            <el-col class="search_col">
                                <el-button type="primary" size="small" native-type="submit"
                                    @click.native.prevent="handleSearch">搜索</el-button>
                            </el-col>
                            <el-col class="search_col">
                                <el-button size="small" @click="resetSearchForm()">重置</el-button>
                            </el-col>
                            <el-col class="btn_col">
                                <el-button type="primary" size="small" @click="doForm()">新增图片</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="page_box" ref="pageBox">
                    <el-table v-loading="loading" row-key="id" size="small" class="el_table" cell-class-name="el_table_cell"
                        header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
                        <el-table-column label="图片" width="110">
                            <template slot-scope="scope">
                                <el-image style="width: 100px; height: 100px" :src="scope.row.web_path"
                                    fit="contain"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                        <el-table-column label="分类">
                            <template slot-scope="scope">
                                {{ scope.row.sort ? scope.row.sort.name : '' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="size_name" label="大小" width="140"> </el-table-column>
                        <el-table-column label="创建日期" prop="created_at" width="140">
                        </el-table-column>
                        <el-table-column fixed="right" align="right" header-align="right" label="操作" width="70">
                            <template slot-scope="scope">
                                <el-button @click="deleteItem(scope.row)" class="btn" type="text" icon="el-icon-delete"
                                    size="small">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page_pagination">
                        <el-pagination background layout="total,prev,pager,next,jumper"
                            @current-change="handleCurrentChange" :total="pageConfig.counts"
                            :page-size="pageConfig.pageSize" :current-page="pageConfig.pageIndex">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="上传图片" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false"
            width="500px" @close="clearFile">
            <el-form ref="modelForm" label-width="80px" size="small">
                <el-form-item label="分类" size="small" :required="true">
                    <el-select v-model="sortID" size="small" popper-class="select" placeholder="选择分类" @change="sortChange">
                        <el-option v-for="item in sortOptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择图片" size="small">
                    <el-upload class="upload-demo" multiple ref="upload" :data="postData" :action="upUrl"
                        :headers="uploadHeaders" name="upfile" :on-change="handleChange" :accept="acceptFile"
                        :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" list-type="picture"
                        :auto-upload="false">
                        <el-button size="small" type="primary">选择图片</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button type="primary" native-type="submit" @click.native.prevent="handleSubmit" :loading="btnLogining"
                    size="small">确 定</el-button>
                <el-button @click="visible = false" size="small">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import resourceApi from "@/api/resource";
import requestApi from "@/lib/request";
export default {
    data() {
        return {
            loading: false,
            sortLoading: false,
            btnLogining: false,
            visible: false,
            searchForm: {
                sort_id: 0,
                type: 1,
                key: "",
            },
            sortList: [
                {
                    id: 0,
                    name: "全部分类",
                },
            ],
            sortOptions: [],
            listData: [],
            pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
            tableHeight: 500,
            sortID: null,
            fileNum: 0,
            postData: { sort_id: 0 },
            fileList: [],
            uploadHeaders: [],
            upUrl: "/api/upload?action=uploadimage",
            acceptFile: "image/*",
            upNum: {
                sucNum: 0,
                errorNum: 0,
            },
        };
    },
    _upLoadding: null,
    mounted() {
        onTableHeight(this);
        let token = window.sessionStorage.getItem("token");
        let timestamp = requestApi.getTimeStamp();
        let sign = requestApi.createSign({}, timestamp);
        this.uploadHeaders = {
            Auth: token,
            timestamp: timestamp,
            sign: sign
        };
        this.getSortList();
        this.getList();
    },
    methods: {
        sortChange() {
            this.postData.sort_id = this.sortID;
        },
        getSortList() {
            this.sortLoading = true;
            resourceApi
                .sortList()
                .then((res) => {
                    this.sortList = this.sortList.concat(res.list);
                    this.sortOptions = res.list;
                })
                .catch(() => { })
                .finally(() => {
                    this.sortLoading = false;
                });
        },
        //查询
        handleSearch() {
            this.getList();
        },
        resetSearchForm() {
            this.searchForm = {
                sort_id: 0,
                type: 1,
                key: "",
            };
            this.getList(true);
        },
        //分页
        handleCurrentChange(val) {
            this.getList(true, val);
        },
        //列表
        getList(pageIndex = 1) {
            this.loading = true;
            resourceApi
                .resourceList({
                    ...this.searchForm,
                    page_size: this.pageConfig.pageSize,
                    page_index: pageIndex,
                })
                .then((res) => {
                    this.loading = false;
                    this.listData = res.list;
                    this.pageConfig.pageIndex = res.page.page_index;
                    this.pageConfig.counts = res.page.counts;
                })
                .catch((res) => {
                    this.loading = false;
                });
        },
        //删除管理员
        deleteItem(row) {
            this.$confirm("确定是否删除该项?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let message = this.$message({
                        message: "请求中",
                        iconClass: "el-icon-loading",
                        customClass: "message-loading",
                        duration: 0,
                        offset: 60,
                    });
                    resourceApi
                        .resourceDelete({
                            id: row.id,
                        })
                        .then((res) => {
                            message.close();
                            this.$message({
                                message: "删除成功",
                                type: "success",
                                offset: 60,
                            });
                            this.getList(this.pageConfig.pageIndex);
                        })
                        .catch((res) => {
                            message.close();
                        });
                })
                .catch(() => { });
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.fileNum = fileList.length;
        },
        handlePreview(file) {
            console.log(file);
        },
        handleChange(file, fileList) {

            if (file.status == "ready")
                this.fileNum = fileList.length;
            console.log(file);
            if (file.status == "success") this.upNum.sucNum++;
            if (file.status == "fail") this.upNum.errorNum++;
            if (this.upNum.sucNum + this.upNum.errorNum == this.fileNum) {
                this.btnLogining = false;
                // this._upLoadding.close();
                this.$message({
                    message:
                        "上传成功" +
                        this.upNum.sucNum +
                        "个，失败" +
                        this.upNum.errorNum +
                        "个",
                    type: this.upNum.errorNum == 0 ? "success" : "error",
                    offset: 60,
                });
                if (this.upNum.sucNum > 0) this.getList();
                this.upNum = {
                    sucNum: 0,
                    errorNum: 0,
                };
                this.visible = false;
            }
        },
        clearFile() {
            if (this.$refs.upload)
                this.$refs.upload.clearFiles();
        },
        doForm() {
            this.sortID = null;
            this.visible = true;
        },
        handleSubmit() {
            console.log(this.fileList);
            if (this.sortID == null) {
                this.$message({
                    message: "请选择分类",
                    type: "error",
                    offset: 60,
                });
                return;
            }
            if (this.fileNum <= 0) {
                this.$message({
                    message: "请选择图片",
                    type: "error",
                    offset: 60,
                });
                return;
            }
            this.btnLogining = true;
            // this._upLoadding = this.$loading({
            //     lock: true,
            //     customClass: "loading",
            //     text: "上传中",
            // });
            this.$refs.upload.submit();
        }
    },
};
</script>
<style scope></style>
  